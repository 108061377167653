<template>
  <Page>
    <template v-slot:content>
      <div>
        <SectionHeader
          :title="$t('pages.TermsConditionsPage.title')"
          :subtitle="$t('pages.TermsConditionsPage.subtitle')"
          type="primary"
          titleSize="large"
        ></SectionHeader>
      </div>
      <div id="DIV_1">
        <div id="DIV_2">
          <div id="DIV_3">
            <p id="P_1">
              <b id="B_1"
                >{{ $t("pages.TermsConditionsPage.contents.1.title") }}
              </b>
            </p>
            <p id="P_2">
              {{ $t("pages.TermsConditionsPage.contents.1.content") }}
            </p>
            <p id="P_3">
              <b id="B_2">{{
                $t("pages.TermsConditionsPage.contents.2.title")
              }}</b>
            </p>
            <p id="P_4">
              {{ $t("pages.TermsConditionsPage.contents.2.content") }}
            </p>
            <p id="P_5">
              <b id="B_3">{{
                $t("pages.TermsConditionsPage.contents.3.title")
              }}</b>
            </p>
            <p id="P_6">
              {{ $t("pages.TermsConditionsPage.contents.3.content0") }}<br />
              {{ $t("pages.TermsConditionsPage.contents.3.content1") }}<br />
              {{ $t("pages.TermsConditionsPage.contents.3.content2") }}<br />
              {{ $t("pages.TermsConditionsPage.contents.3.content3") }}<br />
              {{ $t("pages.TermsConditionsPage.contents.3.content4") }}
            </p>
            <p id="P_7">
              <b id="B_4">{{
                $t("pages.TermsConditionsPage.contents.4.title")
              }}</b>
            </p>
            <p id="P_8">
              {{ $t("pages.TermsConditionsPage.contents.4.content") }}
            </p>

            <p id="P_7">
              <b id="B_4">{{
                $t("pages.TermsConditionsPage.contents.5.title")
              }}</b>
            </p>
            <p id="P_8">
              {{ $t("pages.TermsConditionsPage.contents.5.content1") }} <br />
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content2") }}
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content3") }}
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content4") }}
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content5") }}
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content6") }}
            </p>
            <p id="P_8" style="tab-size: 4;">
              {{ $t("pages.TermsConditionsPage.contents.5.content7") }}
            </p>

            <p id="P_10">
              <b id="B_5">{{
                $t("pages.TermsConditionsPage.contents.6.title")
              }}</b>
            </p>
            <p id="P_11">
              {{ $t("pages.TermsConditionsPage.contents.6.content1") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.6.content2") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.6.content3") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.6.content4") }}
            </p>
            <p id="P_12">
              <b id="B_6">{{
                $t("pages.TermsConditionsPage.contents.7.title")
              }}</b>
            </p>
            <p id="P_13">
              {{ $t("pages.TermsConditionsPage.contents.7.content1") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.7.content2") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.7.content3") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.7.content4") }} <br />
              {{ $t("pages.TermsConditionsPage.contents.7.content5") }}
            </p>

            <p id="P_14">
              <b id="B_7">{{
                $t("pages.TermsConditionsPage.contents.8.title")
              }}</b>
            </p>

            <p id="P_15">
              {{ $t("pages.TermsConditionsPage.contents.8.content1") }}
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="
                  $t('pages.TermsConditionsPage.urlPrefix') +
                    $t('pages.TermsConditionsPage.contents.8.url1')
                "
                id="A_1"
                >{{ $t("pages.TermsConditionsPage.contents.8.url1") }}</a
              >
              <br />
              {{ $t("pages.TermsConditionsPage.contents.8.content2") }}<br />
              {{ $t("pages.TermsConditionsPage.contents.8.content3") }}<br />
            </p>

            <p id="P_14">
              <b id="B_7">{{
                $t("pages.TermsConditionsPage.contents.9.title")
              }}</b>
            </p>
            <p id="P_15">
              {{ $t("pages.TermsConditionsPage.contents.9.content1") }}
              <br /><br />
              {{ $t("pages.TermsConditionsPage.contents.9.content2") }}
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="
                  $t('pages.TermsConditionsPage.urlPrefix') +
                    $t('pages.TermsConditionsPage.contents.9.url1')
                "
                id="A_1"
                >{{ $t("pages.TermsConditionsPage.contents.9.url1") }}</a
              >
              <br />{{ $t("pages.TermsConditionsPage.contents.9.content3") }}
              <a
                target="_blank"
                rel="noopener noreferrer"
                :href="
                  $t('pages.TermsConditionsPage.urlPrefix') +
                    $t('pages.TermsConditionsPage.contents.9.url2')
                "
                id="A_1"
                >{{ $t("pages.TermsConditionsPage.contents.9.url2") }}</a
              >.
            </p>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import SectionHeader from "@/components/molecules/SectionHeader/SectionHeader";
export default {
  name: "TermsConditionsPage",
  components: {
    SectionHeader,
    Page
  },
  metaInfo() {
    return {
      title: this.$t("meta.terms.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.terms.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.terms.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.terms.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.terms.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.terms.image")
        }
      ]
    };
  }
};
</script>

<style scoped lang="css" src="./TermsConditionsPage.css"></style>
